.input {
  width: 100%;
  height: 72px;
  padding: 24px 4px 20px;
  font-size: 19pt;
  font-weight: 500;
  letter-spacing: 0.5pt;
  background: none;
  border: none;
  color: #fff;
  border-bottom: solid #898e93 2px;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &::placeholder {
    color: #898e93;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}
