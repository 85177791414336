.exp {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}
