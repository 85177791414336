html,
body {
  box-sizing: border-box;
  height: 100%;
  font-size: 16px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  min-width: 320px;
  font-family: "Raleway", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 300;
  background: $bg-color;
}

img {
  max-width: 100%;
  height: auto;
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
}

#root {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

ul {
  @include list-reset;
}

html[lang=ru] .en {
  display: none;
}

html[lang=en] .ru {
  display: none;
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}

@media (max-width: 1199px) {
  .container {
    max-width: 960px;
  }
}
