@import '../../../scss/mixins.scss';

.video {
  background-image: linear-gradient(to bottom, #eef0f3, #d8dade);
}

.video-inner {
  max-width: 1150px;
  margin: 0 auto;
  padding: 128px 0;
}

.video-iframeWrap {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  background-color: #dfe1e5;
}

.video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  margin: auto;
}

@media (max-width: 1023px) {
  .video-inner {
    padding: 0;
  }
}
