.formWrap {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  padding: 128px 49px 64px;
  background-color: #131d26;
  transition: all 550ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

.formWrap-close {
  display: block;
  margin: 0 auto 64px;
  width: 26px;
  height: 26px;
  font-size: 0;
  line-height: 0;
  background: url('/images/icons/close.png') no-repeat center center;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    opacity: 0.5;
  }
}

.formWrap.__contactsPage {
  position: relative;

  .formWrap-close {
    display: none;
  }
}

.form {
  max-width: 1056px;
  margin: 0 auto;
  color: #898e93;
}

.form-inputWrap {
  padding: 0 15px;
  width: 50%;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 64px;
}

.form-services,
.form-budget {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-subtitle {
  margin: 0 0 28px;
  padding: 0 15px;
  font-size: 19pt;
  font-weight: 500;
  letter-spacing: initial;
  color: #898e93;
}

.form-desc {
  font-size: 10pt;
  font-weight: 500;
  letter-spacing: initial;
  padding: 1em 15px 0;
  color: #898e93;
  display: inline-block;
}

.form-inputWrap.__services {
  width: calc(100% / 3);
  margin-bottom: 30px;
}

.form-inputWrap.__comment {
  width: 100%;
}

.form-textarea {
  background: none;
  width: 100%;
  display: block;
  padding: 28px;
  font-size: 19pt;
  font-weight: 500;
  letter-spacing: 0.5pt;
  min-height: 134px;
  color: #fff;
  border: solid #898e93 2px;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
}

.form-budgetsInner {
  display: flex;
  justify-content: space-between;
}

.form-inputWrap.__budget {
  width: 25%;
}

.form-comment {
  margin-bottom: 64px;
}

.form-send {
  display: block;
  margin: 58px auto 0;
  width: 312px;
  max-width: 100%;
  background: none;
  border: none;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  padding: 32px 0;
  font-size: 19pt;
  font-weight: 900;
  letter-spacing: 0.5pt;
  color: #fff;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: #131d26;
    cursor: pointer;
  }

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.form-messageWrap {
  position: relative;
  margin-bottom: 82px;
}

.form-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: 400;
  opacity: 0;
  transition: all 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__active {
    opacity: 1;
  }

  &.__success {
    color: rgba(18, 224, 111, 1);
  }

  &.__error {
    color: rgba(226, 16, 61, 1);
  }
}

@media (max-width: 1023px) {
  .formWrap {
    padding: 96px 17px 30px;
  }
}

@media (max-width: 991px) {
  .form-inputWrap {
    width: 100%;
  }

  .form {
    max-width: 750px;
  }

  .form-inputWrap.__services {
    width: 50%;
  }

  .form-inputWrap.__budget {
    width: 100%;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .form-send {
    margin: 28px auto 0;
  }
}

@media (max-width: 767px) {
  .form {
    max-width: 570px;
  }

  .form-inputWrap.__services {
    width: 100%;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 400px) {
  .form-desc {
    margin-top: -25px;
    padding-top: 0;
    width: 100%;
    margin-bottom: 28px;
  }
}
