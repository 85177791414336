.casesWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

.cases {
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 62px 62px 128px;
  background-image: linear-gradient(to bottom, #eef0f3, #d8dade);
  overflow: hidden;
}

.cases-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .cases {
    padding: 0 32px 36px 32px;
  }
}
