@import '../../scss/mixins.scss';

$transition: 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
$color: #0d294c;

.header {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 62px 60px 0;
  transition: opacity 800ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  pointer-events: none;

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }

  &::before {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eef0f3;
    transition: opacity 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    opacity: 0;
    pointer-events: none;
  }
}

.header-logo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 54px;
  margin-left: -3px;
  pointer-events: all;
}

.header-logoImage {
  display: block;
  transition: opacity $transition;

  &.__white {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
  }
}

.header-lang {
  @include btn-reset;

  line-height: 1.2;
  color: #000;
  text-transform: uppercase;
  transition: opacity $transition;

  &:first-of-type {
    margin-right: 5px;
  }

  &:hover {
    text-decoration: underline;
  }

  &.__active {
    opacity: 0.3;
    text-decoration: none;
    pointer-events: none;
    cursor: auto;
  }
}

.header-toggle {
  @include btn-reset;

  display: none;
  pointer-events: all;
}

.nav-list {
  display: flex;
}

.nav-link {
  display: inline-block;
  padding: 10px 21px;
  color: #0d294c;
  font-size: 12pt;
  font-weight: 900;
  letter-spacing: 0.5pt;
  transition: opacity $transition;
  pointer-events: all;

  &:hover {
    opacity: 0.5;
  }

  &:active {
    transform: scale(0.9);
  }

  &.__active {
    opacity: 0.5;
    pointer-events: none;
    cursor: auto;
  }
}

.nav-item {
  &:last-child .nav-link {
    padding-right: 0;
  }
}

.header.__experience {
  .header-logoImage {
    opacity: 0;

    &.__white {
      opacity: 1;
    }
  }

  .header-lang {
    color: #fff;
  }
}

.header.__contacts {
  .nav-link {
    color: #fff;
  }
}

.header.__case {
  .header-logoImage {
    opacity: 0;

    &.__white {
      opacity: 1;
    }
  }

  .header-lang {
    color: #fff;
  }

  .nav-link {
    color: #fff;
  }
}

.header-toggleClose {
  visibility: visible;
  opacity: 0;
}

@media (max-width: 1023px) {
  .header {
    flex-wrap: wrap;
    padding: 36px 32px 0;
    pointer-events: none;
  }

  .nav-link {
    padding: 11px 13px;
    font-size: 16pt;
    pointer-events: none;
  }

  .header-toggle {
    position: relative;
    display: flex;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-top: 4px;
    font-size: 0;

    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 4px;
      background-color: $color;
      box-shadow: 10px -10px 0 0 $color, 10px 10px 0 0 $color;
      transition: opacity $transition;
    }
  }

  .header.__experience {
    .header-logoImage {
      opacity: 1;

      &.__white {
        opacity: 0;
      }
    }

    .header-lang {
      color: #fff;
    }
  }

  .header.__contacts {
    .header-logoImage {
      opacity: 0;

      &.__white {
        opacity: 1;
      }
    }

    .nav-link {
      color: $color;
    }

    .header-lang {
      color: #fff;
    }

    .header-toggle {
      &::before {
        background-color: #fff;
        box-shadow: 10px -10px 0 0 #fff, 10px 10px 0 0 #fff;
      }
    }
  }

  .header.__case {
    .header-toggle {
      &::before {
        background-color: #fff;
        box-shadow: 10px -10px 0 0 #fff, 10px 10px 0 0 #fff;
      }
    }
  }

  .nav {
    width: 100%;
    margin-left: -13px;
    opacity: 0;
    transition: opacity 350ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    pointer-events: none;
  }

  .nav-list {
    flex-wrap: wrap;
  }

  .header-toggleClose {
    position: absolute;
    top: 50%;
    right: 0;
    flex-shrink: 0;
    display: block;
    width: 30px;
    height: 30px;
    transform: translateY(-50%);
    transition: opacity $transition;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      margin-top: -2px;
      width: 30px;
      height: 4px;
      background-color: $color;
      box-shadow: none;
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .header.__open {
    position: fixed;
    pointer-events: all;

    .header-toggle {
      position: relative;

      .header-toggleClose {
        visibility: visible;
        opacity: 1;
      }

      &::before {
        opacity: 0;
      }
    }

    .nav-link {
      color: $color;
      pointer-events: all;
    }

    .header-lang {
      color: #000;
    }

    .header-logoImage {
      opacity: 1;

      &.__white {
        opacity: 0;
      }
    }

    .nav {
      pointer-events: all;
      opacity: 1;
    }

    &::before {
      opacity: 1;
      pointer-events: all;
      transition-delay: 0ms;
    }
  }
}

@media (max-width: 1023px) and (orientation: portrait) {
  .nav-list {
    flex-direction: column;
  }

  .nav {
    margin-left: -20px;
  }

  .nav-link {
    font-size: 19pt;

    &::before {
      content: '';
      display: inline-block;
      width: 64px;
      height: 5px;
      background-color: #0d294c;
      vertical-align: middle;
      margin-right: 32px;
      opacity: 0;
    }

    &.__active::before {
      opacity: 1;
    }
  }
}
