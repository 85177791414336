@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin btn-reset {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
}
