@import '../../../scss/mixins.scss';

.mask {
  padding: 128px 0;
}

.mask-container {
  display: flex;
  flex-wrap: wrap;
}

.mask-col {
  width: 50%;
  padding: 0 15px;
}

.mask-text {
  display: block;
  margin: 0 0 1rem;
  font-size: 19pt;
  line-height: 1.35em;

  &.__slogan {
    font-size: 31pt;
    font-weight: 700;
  }

  &.__medium {
    font-weight: 500;
  }
}

@media (max-width: 1023px) {
  .mask {
    padding: 48px 0;
  }

  .mask-container {
    padding: 0 16px;
    max-width: calc(100% - 32px) !important;
  }

  .mask-text {
    &.__slogan {
      font-size: 19pt;
    }
  }
}

@media (max-width: 991px) {
  .mask-container {
    flex-direction: column;
  }

  .mask-col {
    width: 100%;
  }

  .mask-cext {
    &.__first {
      margin-bottom: 0.5rem;
    }
  }
}
