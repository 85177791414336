.dtContent {
  padding: 60px 0 60px;

  &.__indent {
    padding: 260px 0 60px;
    background-color: #fff;
  }

  &.__white {
    color: #fff;
  }
}

.dtContentWrap {
  margin-top: -200px;
  padding-top: 200px;
  padding-bottom: 188px;

  &.__noGallery {
    padding-bottom: 88px;
  }
}

.dtContent-inner {
  display: flex;
  padding: 60px 0;
  padding-left: 64px;

  .text-bold {
    font-weight: 700;
  }

  .text-normal {
    font-weight: 500;
  }

  .block-line {
    width: 128px;
    height: 8px;
    background-color: #fff;
    margin: 96px 0 0;
  }

  .text-uppercase {
    text-transform: uppercase;
  }

  .txt-size-10 {
    font-size: 10pt;
  }

  .txt-size-15 {
    font-size: 15pt;
  }

  .txt-size-16 {
    font-size: 16pt;
  }

  // .txt-size-12 {
  //   font-size: 12pt;
  // }

  // .txt-size-12 {
  //   font-size: 13pt;
  // }

  // .txt-size-13 {
  //   font-size: 13pt;
  // }

  // .txt-size-13 {
  //   font-size: 10pt;
  // }

}

.dtContent-image,
.dtContent-imageText,
.dtContent-text,
.dtContent-textLeft,
.dtContent-textRight {
  width: 50%;
}

.dtContent-image,
.dtContent-imageText,
.dtContent-textLeft {
  padding-right: 64px;
}

.dtContent-textLeft.__bold {
  font-weight: 700;
}

.dtContent-imageText {
  font-size: 19pt;
  font-weight: 900;

  p {
    margin: 0;
    margin-bottom: 1rem;
  }

  span {
    font-size: 80pt;
    display: block;
    margin-bottom: -0.33em;
    margin-top: -0.4em;
  }
}

.dtContent-image {
  align-self: center;

  &.__small {
    max-width: 100%;
    width: 310px;
    margin: 0 calc((50% - 310px) / 2);
  }
}

.dtContent-textRight,
.dtContent-text,
.dtContent-textLeft {
  p {
    margin: 0;
    margin-bottom: 1rem;
    line-height: 1.35em;
  }
}

@media (max-width: 1023px) {
  .dtContent-inner {
    padding: 16px 15px;

    .block-line {
      margin: 20px 0 28px;
    }
  }

  .dtContentWrap {
    margin-top: -95px;
    padding-top: 95px;
    padding-bottom: 68px;

    &.__noGallery {
      padding-bottom: 36px;
    }
  }

  .dtContent {
    padding: 0;

    &.__indent {
      padding: 104px 0 16px;

      .dtContent-inner {
        padding: 60px 15px 0;
      }
    }

    &.__white {
      padding-top: 16px;
    }
  }

  .dtContent-container {
    max-width: calc(100% - 32px);
  }

  .dtContent-textLeft.__title {
    font-size: 19pt !important;
  }
}

@media (max-width: 991px) {
  .dtContent-inner {
    flex-direction: column;
    padding: 16px 15px;
  }

  .dtContent {
    &.__indent {
      .dtContent-inner {
        padding: 18px 15px 0;
      }
    }
  }

  .dtContent-textLeft,
  .dtContent-textRight,
  .dtContent-image,
  .dtContent-imageText,
  .dtContent-text {
    width: 100%;
  }

  .dtContent-text {
    margin-top: 40px;
  }

  .dtContent-imageText {
    margin-bottom: -39px;
  }

  .dtContent-image {
    padding-right: 0;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .dtContent-inner {
    padding-left: 64px;
  }
}
