.promo {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #eef0f3, #d8dade);
  overflow: hidden;
  transition: 200ms ease;

  &.__activeExit {
    opacity: 0;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  14% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.promo.__flash {
  animation: flash 1900ms cubic-bezier(0.5, 1, 0.89, 1);
}

$time: 1900ms;

.promo-text {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0;
  color: rgba(13, 41, 76, 0.2);
  font-family: 'Oswald', sans-serif;
  font-size: 134pt;
  font-weight: 700;
  letter-spacing: initial;
  opacity: 0;
  text-transform: uppercase;
  transition: $time cubic-bezier(0.25, 0.46, 0.45, 0.94);

  &.__top {
    top: -0.25em;
    transform: translateX(-187%);
  }

  &.__middle {
    top: 0.75em;
    transform: translateX(63%);
    transition-delay: $time;
  }

  &.__bottom {
    top: 1.75em;
    transform: translateX(-161%);
    transition-delay: $time * 2;
  }
}

.promo.__active .promo-text {
  opacity: 1;

  &.__top {
    transform: translateX(-87%);
  }

  &.__middle {
    transform: translateX(-37%);
  }

  &.__bottom {
    transform: translateX(-61%);
  }
}

.promo-pandaImage {
  height: 120vh;
  min-height: 120vh;
  max-height: 120vh;
  max-width: none;
}

$transitionPanda: 1600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

.promo-pandaLinkWrap {
  visibility: hidden;
  transform: translateY(100px) scale(1.1);
  transition: $transitionPanda 200ms;
}

.promo-pandaLink,
.promo-pandaLinkWrap {
  position: absolute;
  z-index: 10;
  left: 50%;
  bottom: -20.6%;
}

.promo-pandaLink {
  display: block;
  transform: translate(-50%, 0);
  transition: $transitionPanda;

  &:hover {
    transform: translate(-50%, 0) scale(1.075);
  }
}

$transitionPandaText: 620ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

.promo-pandaTextWrap {
  position: absolute;
  z-index: 10;
  bottom: 27%;
  left: 66%;
  opacity: 0;
  visibility: hidden;
  transition: $transitionPandaText 1200ms;
}

.promo-pandaText {
  display: inline-block;
  font-size: 31pt;
  letter-spacing: 0.1em;
  line-height: 1.33em;
  color: #0d294c;
  white-space: nowrap;
  text-transform: uppercase;
  transition: $transitionPandaText;

  &:hover {
    transform: scale(1.05);
  }
}

.promo.__flash .promo-pandaLinkWrap {
  visibility: visible;
  transform: translateY(0) scale(1);
}

.promo.__flash .promo-pandaTextWrap {
  opacity: 1;
  visibility: visible;
}

.promo.__splash {
  animation: none;

  .promo-text {
    transition: none;
  }

  .promo-pandaLinkWrap {
    visibility: visible;
    transform: translateY(0) scale(1);
    transition: none;
  }

  .promo-pandaTextWrap {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
}

@media (max-width: 1023px) {
  .promo-pandaImage {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }

  .promo-pandaTextWrap {
    left: 0;
    bottom: 64px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .promo-pandaText {
    white-space: wrap;
  }

  .promo-text {
    display: none;
  }
}
