
.dtPromo {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 80px);
  min-width: 100%;
  max-width: 100%;
  min-height: calc(50vh + 80px);
  max-height: calc(100vh - 80px);
  padding: 128px;
  padding-bottom: 0;
  color: #fff;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  background-attachment: fixed;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.33);
  }
}

.dtPromo-close {
  position: absolute;
  top: 70px;
  left: 50%;
  display: block;
  width: 26px;
  height: 26px;
  font-size: 0;
  line-height: 0;
  background: url('/images/icons/close.png') no-repeat center center;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transform: translateX(-50%);

  &:hover {
    opacity: 0.5;
  }
}

.dtPromo-title {
  margin: 0;
  font-family: Oswald, sans-serif;
  font-size: 80pt;
  font-weight: 700;
  letter-spacing: 0.5pt;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;
  display: inline-block;
}

.dtPromo-event {
  margin: 0;
  font-size: 10pt;
  font-weight: 400;
  letter-spacing: 1pt;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.3;
  padding-left: 6px;
  display: inline-block;
  margin-bottom: 2px;
}

@media (max-width: 1290px) {
  .dtPromo-title {
    font-size: 60pt;
  }
}

@media (max-width: 1023px) {
  .dtPromo {
    padding-bottom: 0;
    max-height: calc(100vh - 40px);
    height: calc(100vh - 40px);
    background-attachment: scroll;
  }

  .dtPromo-title {
    font-size: 45pt;
  }

  .dtPromo-event {
    font-size: 9pt;
  }

  .dtPromo-close {
    top: 40px;
  }
}

@media (max-width: 1023px) and (orientation: portrait) {
  .dtPromo {
    height: calc(100vh - 248px);
    padding-left: 32px;
    padding-right: 32px;
  }

  .dtPromo-title {
    font-size: 12vw;
  }

  .dtPromo-event {
    font-size: 10pt;
  }
}

@media (max-width: 600px) and (orientation: landscape), (max-height: 600px) and (orientation: portrait) {
  .dtPromo-title {
    font-size: 31pt;
  }

  .dtPromo-event {
    font-size: 6pt;
  }
}
