@import '../../../scss/mixins.scss';

.buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

.social {
  display: flex;
  margin-bottom: 26px;
}

.social-item {
  margin-left: 35px;
  &:first-child {
    margin-left: auto;
  }
}

.social-link {
  display: block;
  width: 25px;
  height: 25px;
  font-size: 0;
  line-height: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__facebook {
    background-image: url('/images/icons/icon-facebook.png');

    &:hover {
      background-image: url('/images/icons/icon-facebook-hover.png');
    }
  }

  &.__instagram {
    background-image: url('/images/icons/icon-instagram.png');

    &:hover {
      background-image: url('/images/icons/icon-instagram-hover.png');
    }
  }
}

.buttons-formLink {
  @include btn-reset;

  display: block;
  padding: 20px 24px;
  border: solid 2px #fff;
  color: #fff;
  font-size: 12pt;
  font-weight: 800;
  letter-spacing: 0.5pt;
  transition: 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    color: #0d294c;
    background: #fff;
  }
}

@media (max-width: 1023px) {
  .social {
    margin-bottom: 17px;
  }

  .social-item {
    margin-left: 24px;
  }

  .social-link {
    width: 18px;
    height: 18px;
  }

  .buttons-formLink {
    padding: 14px;
    font-size: 8pt;
  }
}

@media (max-width: 400px) and (orientation: portrait) {
  .social-item {
    margin-left: 10px;
  }

  .buttons-formLink {
    padding: 12px;
    font-size: 6pt;
  }
}
