@import '../../scss/mixins.scss';

.dtInfo {
  padding: 120px 0;
  margin-bottom: -320px;
}

.dtInfo-Container {
  color: #fff;
}

.dtInfo-textWrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 64px;
  margin-bottom: 119px;
}

.dtInfo-metaWrap {
  width: 41.666667%;
  padding-left: 15px;
  padding-right: 64px;

  &::before {
    content: '';
    width: 128px;
    height: 8px;
    background-color: #fff;
    margin: 20px 0 28px;
    display: block;
  }
}

.dtInfo-metaList {
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
}

.dtInfo-metaItem {
  font-size: 12pt;
  font-weight: 300;
  letter-spacing: initial;
  line-height: 1.5em;
  text-transform: uppercase;

  &.__date {
    margin-bottom: 0.25em;

    &::before {
      content: '/';
      padding-right: 0.5em;
    }
  }

  &.__location {
    margin-bottom: 0.25em;
  }

  &.__event {
    margin: 0;
    margin-bottom: 1rem;
    font-weight: bolder;

    &::after {
      content: '';
      margin-left: 10px;
      display: inline-block;
      width: 30px;
      height: 30px;
      vertical-align: middle;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &.__eventYear::after {
    background-image: url('/images/icons/year.png');
  }

  &.__mercury::after {
    background-image: url('/images/icons/mercury.png');
  }

  &.__top::after {
    background-image: url('/images/icons/top.png');
  }

  &.__beaworld::after {
    background-image: url('/images/icons/beaworld.png');
  }

  &.__eventex::after {
    background-image: url('/images/icons/eventex.png');
  }

  &.__tags {
    margin-top: auto;
  }

  &.__normal {
    font-weight: 300;
  }
}

.dtInfo-metaHashtag {
  display: block;
  font-size: 19pt;
  font-weight: 300;
  letter-spacing: initial;
  line-height: 1.5em;
}

.dtInfo-infoText {
  width: 58.333333%;
  margin: 0;
  margin-bottom: 1rem;
  padding: 0 15px;
  font-size: 31pt;
  font-weight: 300;
  letter-spacing: initial;
  line-height: 1.35em;
}

.dtInfo-video {
  width: 100%;
  margin-bottom: 16px;
}

.dtInfo-videoInner {
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  background-color: #dfe1e5;
}

.dtInfo-videoIframe {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.dtInfo-videoImage {
  width: 100%;
}

@media (max-width: 1023px) {
  .dtInfo {
    padding: 68px 0;
    margin-bottom: -160px;
  }

  .dtInfo-Container {
    max-width: calc(100% - 32px) !important;
  }

  .dtInfo-infoText {
    font-size: 19pt;
  }

  .dtInfo-textWrap {
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 16px;
  }

  .dtInfo-video {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .dtInfo-textWrap {
    padding-left: 64px;
  }
}

@media (max-width: 991px) {
  .dtInfo-textWrap {
    flex-direction: column;
  }

  .dtInfo-metaWrap {
    width: 100%;
  }

  .dtInfo-infoText {
    width: 100%;
  }

  .dtInfo-metaItem.__tags {
    margin-bottom: 1rem;
  }
}
