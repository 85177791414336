.case {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 1200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

.case-promoTitle {
  position: absolute;
  z-index: 10;
  left: 0;
  top: calc(50% + 31px);
  width: auto;
  height: auto;
  max-width: 50%;
  margin: 0;
  transition: all 1500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.case-promoText {
  display: block;
  max-width: 50%;
  font-size: 31pt;
  font-weight: 300;
  letter-spacing: initial;
  letter-spacing: 0.5pt;
  color: #0d294c;
  text-transform: uppercase;
  line-height: 1.3;
}

.case-info {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  max-width: 60%;
}

.case-typeEvent {
  margin: 0;
  padding-left: 6px;
  font-size: 10pt;
  font-weight: 500;
  letter-spacing: 1pt;
  color: #333;
  text-transform: uppercase;
  line-height: 1.3;
}

.case-title {
  margin: 0 0 40px;
  font-family: 'Oswald', sans-serif;
  font-size: 80pt;
  font-weight: 700;
  letter-spacing: 0.5pt;
  color: #131d26;
  text-transform: uppercase;
  line-height: 1.3;
  transition: all 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.case-linkMore {
  position: relative;
  z-index: 10;
  display: inline-block;
  padding: 20px 24px;
  border: solid 2px #131d26;
  color: #131d26;
  font-size: 12pt;
  font-weight: 800;
  letter-spacing: 0.5pt;
  transition: all 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.case-linkMoreText {
  transition: inherit;
}

.case-linkMoreBg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: inherit;
}

.case-linkMore:hover {
  .case-linkMoreText {
    color: #fff;
  }

  .case-linkMoreBg {
    opacity: 1;
  }
}

.case-image {
  display: block;
  height: 85vh;
  min-height: 85vh;
  max-height: 85vh;
  max-width: none;
  transition: all 1200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.case.__enter .case-image {
  transform: translate(-35px, 35px) scale(0.9);
}

.case.__enterActive .case-image {
  transform: translate(0, 0) scale(1);
}

.case.__exit .case-image {
  transform: translate(0, 0) scale(1);
}

.case.__exitActive .case-image {
  transform: translate(-35px, 35px) scale(0.9);
}

.case.__enter .case-image.__promo {
  transform: translateY(-50px);
}

.case.__enterActive .case-image.__promo {
  transform: translateY(0);
}

.case.__exit .case-image.__promo {
  transform: translateY(0);
}

.case.__exitActive .case-image.__promo {
  transform: translateY(-50px);
}

.case-imageWrap {
  position: absolute;
  left: calc(50% - 33px);
  bottom: -62px;
  transform: translateX(-50%);
}

@media (max-width: 1023px) {
  .case {
    padding: 0 32px 36px;
  }

  .case-promoTitle {
    top: calc(50% + 18px);
  }

  .case-promoText {
    max-width: initial;
    margin-right: 48px;
    font-size: 19pt;
  }

  .case-info {
    max-width: 100%;
  }

  .case-title {
    margin: 0 0 18px;
  }

  .case-image {
    height: 80vh;
    min-height: 80vh;
    max-height: 80vh;
  }

  .case-imageWrap {
    position: absolute;
    left: 50%;
    bottom: -36px;
    transform: translateX(-50%);
  }

  .case-linkMore {
    padding: 14px;
    font-size: 8pt;
  }

  .case-typeEvent {
    padding-left: 0;
    font-size: 8pt;
    margin-left: 4px;
    margin-bottom: 3px;
  }
}

@media (max-width: 1023px) and (orientation: portrait) {
  .case-image {
    height: 72.5vh;
    min-height: 72.5vh;
    max-height: 72.5vh;
  }

  .case-title {
    margin: 0 0 64px;
    font-size: 38pt;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .case-title {
    font-size: 45pt;
  }
}

@media (max-width: 600px) and (orientation: landscape), (max-height: 600px) and (orientation: portrait) {
  .case-title {
    font-size: 31pt;
    margin: 0 0 45px;
  }

  .case-typeEvent {
    font-size: 7pt;
  }
}

@media (max-width: 400px) and (orientation: portrait) {
  .case-linkMore {
    padding: 12px;
    font-size: 6pt;
  }
}
