.dtGallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.dtGallery-image {
  width: 50%;

  &.__large {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .dtGallery-image {
    width: 100%;
  }
}
