@import '../../scss/mixins.scss';

.contacts {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

.contacts-inner {
  display: flex;
}

.contacts-question {
  display: flex;
  justify-content: flex-end;
  width: 41.666667%;
  padding: 0 15px;
}

.contacts-content {
  width: 58.333333%;
  padding: 200px 15px 128px 79px;
  background-color: #131d26;
  color: #fff;
  font-size: 19pt;
  font-weight: 500;
  line-height: 1.35em;
}

.contacts-questionText {
  display: block;
  max-width: 444px;
  padding: 200px 64px 128px 0;
  font-size: 31pt;
  font-weight: 700;
  line-height: 1.35em;
}

.contacts-text {
  margin: 0 0 1rem;
  font-style: normal;
}

.contacts-socialList {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
}

.contact-socialLink {
  display: flex;
  align-items: center;
  padding-left: 4px;
  padding-right: 12px;
  transition: 250ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    color: #aaa;
  }

  &::before {
    content: '';
    width: 49px;
    height: 65px;
    background-size: 25px 25px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &.__whatsapp::before {
    width: 46px;
    height: 62px;
    background-image: url('/images/icons/contacts-whatsapp.png');
    background-size: 22px 22px;
  }

  &.__facebook::before {
    background-image: url('/images/icons/icon-facebook.png');
  }

  &.__instagram::before {
    background-image: url('/images/icons/icon-instagram.png');
  }
}

@media (max-width: 1023px) {
  .contacts-inner {
    flex-direction: column;
  }

  .contacts-question {
    width: 100%;
    order: 1;
    justify-content: flex-start;
  }

  .contacts-content {
    width: 100%;
    padding: 200px 31px 0;
  }

  .contacts-questionText {
    padding: 16px;
    margin-bottom: 0.5rem;
    max-width: none;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .contacts-content {
    padding: 128px 39px 24px;
  }

  .contacts-questionText {
    padding: 32px 24px;
  }
}
