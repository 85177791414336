.dtNext {
  position: relative;
  z-index: 10;
  color: #fff;
  height: 360px;
  padding: 60px 0;
  overflow: hidden;
  display: block;

  &::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #333;
    transition: all 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
}

.dtNext-text {
  display: block;
  max-width: 80%;
  padding: 60px 15px 60px 78px;
  font-family: Oswald, sans-serif;
  font-size: 160px;
  font-weight: 700;
  letter-spacing: 0.5pt;
  text-transform: uppercase;
  color: #fff;
  line-height: 177px;
  margin: 0;
  transition: all 450ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.dtNext:hover {
  &::before {
    opacity: 0.33;
  }

  .dtNext-text {
    transform: translateY(-32px);
  }
}

@media (max-width: 1023px) {
  .dtNext {
    height: 180px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .dtNext-text {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12vw;
    line-height: 1.3;
  }

  .dtNext-container {
    max-width: calc(100% - 32px);
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .dtNext-text {
    padding-left: 64px;
    font-size: 60px;
  }
}

@media (max-width: 600px) and (orientation: landscape), (max-height: 600px) and (orientation: portrait) {
  .dtNext-text {
    font-size: 31pt;
  }
}
