@import '../../../scss/mixins.scss';

.statistics {
  display: flex;
}

.statistics-record {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 41.666667%;
  padding: 0 15px;
  background-color: #131d26;
  color: #fff;
}

.statistics-recordInner {
  max-width: 444px;
  padding: 200px 64px 128px 0;
  text-transform: uppercase;

  &::before {
    content: '';
    display: block;
    width: 128px;
    height: 8px;
    background-color: #fff;
    margin: 20px 0 27px;
  }
}

.statistics-recordTitle {
  font-size: 8pt;
  letter-spacing: 2pt !important;
  margin: 0;
  margin-bottom: 2.5em;
  font-weight: 500;
}

.statistics-recordItem {
  font-size: 1.4vh;
  margin-bottom: 0.75em;
  line-height: 1.35em;
  letter-spacing: 2pt !important;
  font-weight: 500;

  &::after {
    content: '';
    display: inline-block;
    width: 31px;
    height: 31px;
    margin-left: 16px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translate(0, -15%);
    vertical-align: middle;
  }

  &.__eventYear::after {
    background-image: url('/images/icons/year.png');
  }

  &.__mercury::after {
    background-image: url('/images/icons/mercury.png');
  }

  &.__top::after {
    background-image: url('/images/icons/top.png');
  }

  &.__beaworld::after {
    background-image: url('/images/icons/beaworld.png');
  }

  &.__eventex::after {
    background-image: url('/images/icons/eventex.png');
  }
}

.statistics-promo {
  width: 58.333333%;
  background-color: #fff;
  padding: 200px 15px 128px 79px;
}

.statistics-promoText {
  margin: 0;
  margin-bottom: 1rem;
  max-width: 611px;
  font-size: 5vh;
  font-weight: 300;
  line-height: 1.35em;
}

@media (max-width: 1023px) {
  .statistics {
    flex-direction: column-reverse;
  }

  .statistics-record {
    width: 100%;
    align-items: flex-start;
  }

  .statistics-promo {
    width: 100%;
    padding: 200px 31px 0;
  }

  .statistics-promoText {
    max-width: 643px;
  }

  .statistics-recordInner {
    padding: 16px;
  }
}

@media (max-width: 1023px) and (orientation: landscape) {
  .statistics-promo {
    width: 100%;
    padding: 128px 39px 24px;
  }

  .statistics-promoText {
    max-width: 627px;
  }

  .statistics-recordInner {
    padding: 32px 24px;
  }
}
