@import '../../../scss/mixins.scss';

.clients {
  background: #eef0f3;
  color: #000;
  clear: both;
  padding-top: 128px;
  padding-bottom: 128px;
  width: 100%;
}

.clients-subtitle {
  font-size: 19pt;
  font-weight: 500;
  margin: 0;
  margin-bottom: 0.5rem;
  padding: 0 55px;
}

.clients-img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  max-width: 50%;
  max-height: 50%;
  object-fit: contain;
  transform: translateX(-50%);

  &.__active {
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    opacity: 0;
  }
}

.clients-link {
  position: relative;
  display: block;
  width: 100%;
  height: 128px;
  padding: 0 15px;

  &:hover .clients-img.__active {
    opacity: 1;
  }
}

.clients-list {
  @include list-reset;

  display: flex;
  flex-wrap: wrap;
  padding: 32px 0 0;
}

.clients-item {
  width: calc(100% / 6);

  &.__big {
    width: calc(100% / 3);
  }
}

@media (max-width: 1023px) {
  .clients .container {
    max-width: calc(100% - 32px) !important;
  }

  .clients {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .clients-item {
    width: calc(100% / 4);

    &.__big {
      width: calc(100% / 2);
    }
  }
}

@media (max-width: 767px) {
  .clients-item {
    width: calc(100% / 3);

    &.__big {
      width: calc(100% / 1.5);
    }
  }
}

@media (max-width: 575px) {
  .clients-item {
    width: calc(100% / 2);

    &.__big {
      width: 100%;
    }
  }
}
