.formButton {
  background: none;
  padding: 0;
  width: 100%;
  display: inline-block;
  cursor: pointer;
}

.formButton-input {
  display: none;
}

.formButton-text {
  display: inline-block;
  width: 100%;
  padding: 28px;
  border: solid  #898e93 2px;
  font-size: 12pt;
  font-weight: 900;
  letter-spacing: 0.5pt;
  text-align: center;
  color: #fff;
  transition: all 190ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}

.formButton-input:checked + .formButton-text {
  color: #333;
  border-color: #fff;
  background: #fff;
}
