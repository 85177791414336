@import '../../../scss/mixins.scss';

.expertise {
  padding: 128px 0;
  background-color: #fff;
}

.expertise-container {
  display: flex;
}

.expertise-col {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  padding: 0 15px;

  &.__small {
    width: 25%;
  }
}

.expertise-subtitle {
  width: 100%;
  margin: 0;
  font-size: 19pt;
  font-weight: 500;
  line-height: 1.35em;
}

.expertise-listText {
  @include list-reset;

  position: relative;
  width: 100%;
}

.expertise-itemText {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 19pt;
  font-weight: 300;
  opacity: 0;
  margin-bottom: 1rem;
  transition: 200ms cubic-bezier(0.12, 0, 0.39, 0);
  pointer-events: none;

  &.__active {
    opacity: 1;
    pointer-events: all;
    transition-delay: 200ms;
  }
}

.expertise-list {
  @include list-reset;

  margin-bottom: 1rem;
}

.expertise-item {
  margin-bottom: 25px;
  padding-left: 40px;

  &:last-child {
    margin-bottom: 0;
  }
}

.expertise-itemButton {
  @include btn-reset;

  position: relative;
  color: #000;
  font-size: 19pt;
  line-height: 1.35em;
  opacity: 1;
  text-align: left;
  transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &::before {
    content: '';
    position: absolute;
    top: 17px;
    left: -12px;
    display: block;
    width: 4px;
    height: 4px;
    background-color: #000;
    transform-origin: 100% 100%;
    transition: all 200ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &.__active {
    color: rgba(0, 0, 0, 0.5);

    &::before {
      transform: scaleX(8);
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

@media (max-width: 1023px) {
  .expertise {
    padding: 32px 0;
  }

  .expertise-container {
    max-width: calc(100% - 32px);
    padding: 0 16px;
  }

  .expertise-listText {
    display: none;
  }

  .expertise-itemButton {
    @include btn-reset;

    &.__active {
      color: rgba(0, 0, 0, 1);

      &::before {
        transform: scaleX(1);
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }
}

@media (max-width: 991px) {
  .expertise-container {
    flex-direction: column;
  }

  .expertise-col {
    width: 100%;

    &.__small {
      width: 100%;
    }
  }

  .expertise-subtitle {
    margin-bottom: 26px;
  }

  .expertise-list {
    margin-bottom: 25px;
  }
}
