@import '../../../scss/mixins.scss';

$transition: 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

.pagination {
  @include list-reset;

  position: absolute;
  top: 50%;
  right: -2px;
  font-size: 0;
  line-height: 0;
  transform: translate(0, -50%);
  transition: all 1000ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
    transform: translate(10px, -50%);
  }

  &.__enterActive {
    opacity: 1;
    transform: translate(0, -50%);
  }

  &.__exit {
    opacity: 1;
    transform: translate(0, -50%);
  }

  &.__exitActive {
    opacity: 0;
    transform: translate(10px, -50%);
  }
}

.pagination-text {
  position: relative;
  display: block;
  width: 6px;
  height: 6px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transition: $transition;
  }
}

.pagination-link {
  display: block;
  padding: 5px 2px;
  transition: $transition;

  &:hover {
    .pagination-text::before {
      opacity: 0;
    }
  }
}

.pagination-link.__active {
  pointer-events: none;

  .pagination-text::before {
    opacity: 0;
  }
}

@media (max-width: 1023px) {
  .pagination {
    transform: translate(0, -80%);

    &.__enter {
      opacity: 0;
      transform: translate(10px, -80%);
    }

    &.__enterActive {
      opacity: 1;
      transform: translate(0, -80%);
    }

    &.__exit {
      opacity: 1;
      transform: translate(0, -80%);
    }

    &.__exitActive {
      opacity: 0;
      transform: translate(10px, -80%);
    }
  }

  .pagination-text {
    width: 4px;
    height: 4px;
  }

  .pagination-link {
    &:hover {
      transform: scale(1.5);
    }
  }

  .pagination-link.__active {
    transform: scale(1.5);
  }
}

@media (max-width: 1023px) and (orientation: portrait) {
  .pagination {
    transform: translateY(-100%);

    &.__enter {
      opacity: 0;
      transform: translate(10px, -100%);
    }

    &.__enterActive {
      opacity: 1;
      transform: translate(0, -100%);
    }

    &.__exit {
      opacity: 1;
      transform: translate(0, -100%);
    }

    &.__exitActive {
      opacity: 0;
      transform: translate(10px, -100%);
    }
  }
}

@media (max-width: 600px) and (orientation: landscape), (max-height: 600px) and (orientation: portrait) {
  .pagination-link {
    padding: 4px 2px;
  }

  .pagination-text {
    width: 3px;
    height: 3px;
  }
}
