@import '../../scss/mixins.scss';

.details {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  color: #333;
  background-color: #fff;
  transition: all 500ms cubic-bezier(0.455, 0.03, 0.515, 0.955);

  &.__enter {
    opacity: 0;
  }

  &.__enterActive {
    opacity: 1;
  }

  &.__exit {
    opacity: 1;
  }

  &.__exitActive {
    opacity: 0;
  }
}

@media (max-width: 1023px) {
  .details .container {
    max-width: calc(100% - 32px);
  }

  .dtGalleryContainer {
    padding: 0 15px;
  }
}
